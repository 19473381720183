import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import './NavBar.css';
import './Global.css';
import logo from './assets/logo.png';
import colors from './colors';
import { fetchEmailData } from './cms/cmsEmail';

function NavBar({ hideMiddleSection }) {
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [email, setEmail] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [hideCaptcha, setHideCaptcha] = useState(false);
  const [emailClicked, setEmailClicked] = useState(false);

  useEffect(() => {
    const getEmail = async () => {
      const data = await fetchEmailData();
      setEmail(data ? data.email : '');
    };
    getEmail();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        setIsScrollingDown(true);
      } else {
        setIsScrollingDown(false);
      }

      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  const handleCaptchaChange = (value) => {
    if (value && !emailClicked) {
      setCaptchaVerified(true);
      setTimeout(() => {
        setHideCaptcha(true); // Add the class to hide the CAPTCHA
        setTimeout(() => {
          setShowCaptcha(false); // Remove the CAPTCHA from the DOM after the transition
          handleContactClick();
        }, 500); // Match this duration to the CSS transition duration
      }, 1000); // Delay before starting to hide the CAPTCHA
    }
  };

  const handleContactClick = () => {
    if (email && !emailClicked) {
      setEmailClicked(true); // Ensure the email click is only handled once
      window.location.href = `mailto:${email}`;
    }
  };

  const handleClick = () => {
    setShowCaptcha(true);
    setHideCaptcha(false); // Reset the hiding state
  };

  const scrollToSection = (event, id) => {
    event.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="nav-bar">
      <a href="/"> <img src={logo} alt="Logo" className={`nav-logo ${isScrollingDown ? 'fade-out' : 'fade-in'}`} /></a>

      {!hideMiddleSection && (
        <div className="nav-links">
          <a href="/" className="nav-link" onClick={(e) => scrollToSection(e, 'technology')}>Technology</a>
          <a href="/" className="nav-link" onClick={(e) => scrollToSection(e, 'application')}>Applications</a>
          <a href="/" className="nav-link" onClick={(e) => scrollToSection(e, 'team')}>Team</a>
          <a href="/" className="nav-link" onClick={(e) => scrollToSection(e, 'partners')}>Partners</a>
        </div>
      )}

      <div style={{ width: '120px', display: 'flex', justifyContent: 'flex-end' }}>
        <a
          onClick={handleClick}
          className={`nav-link contact-button ${isScrollingDown ? 'fade-out' : 'fade-in'}`}
          style={{
            backgroundColor: '#F5F5F5',
            color: colors.black,
            cursor: 'pointer',
          }}
        >
          Contact
        </a>
      </div>

      {showCaptcha && (
        <div className={`captcha-container ${hideCaptcha ? 'hide' : ''}`}>
          <ReCAPTCHA
            sitekey="6Ldzne8pAAAAALJgOOL3PyjC9Ky0hjUYlaDplY49" 
            onChange={handleCaptchaChange}
          />
        </div>
      )}
    </div>
  );
}

NavBar.propTypes = {
  hideMiddleSection: PropTypes.bool,
};

export default NavBar;
