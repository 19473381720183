import React, { useState, useEffect } from 'react';
import './Global.css';
import Button from './button';
import colors from './colors';
import { fetchHeroData } from './cms/cmsHero';

const Hero = ({ phone }) => {
    const [image, setImage] = useState('');
    const [tagline, setTagline] = useState('');
    const [callToAction, setCallToAction] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchHeroData();
            setImage(data.image);
            setTagline(data.tagline);
            setCallToAction(data.callToAction);
        };
        fetchData();
    }, []);

    const containerStyle = phone ? { height: 'calc(70vh + 30px)' } : { height: 'calc(85vh + 20px)' };
    const textStyle = phone ? { fontSize: '32px', lineHeight:'40px', width:'80%' } : { fontSize: '80px', padding: '20px' };

    return (
        <div>
            <div className={phone ? "hero-image-wrapper-phone" : "hero-image-wrapper"}>
                {image && <img src={image} alt="Hero" className="hero-image" />}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', ...containerStyle }}>
                <h1 style={{ textAlign: 'center', color: colors.white, ...textStyle }}>{tagline}</h1>
                <Button color={colors.green} content={callToAction} textColor={colors.black} />
            </div>
        </div>
    );
};

export default Hero;
