import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import './NavBar.css';
import './Global.css';
import logo from './assets/logo.png';
import colors from './colors';
import { fetchEmailData } from './cms/cmsEmail';

function NavBarPhone({ hideMiddleSection }) {
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [email, setEmail] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [hideCaptcha, setHideCaptcha] = useState(false);
  const [emailClicked, setEmailClicked] = useState(false);

  useEffect(() => {
    const getEmail = async () => {
      const data = await fetchEmailData();
      setEmail(data ? data.email : '');
    };
    getEmail();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        setIsScrollingDown(true);
      } else {
        setIsScrollingDown(false);
      }

      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  const handleCaptchaChange = (value) => {
    if (value && !emailClicked) {
      setCaptchaVerified(true);
      setTimeout(() => {
        setHideCaptcha(true); 
        setTimeout(() => {
          setShowCaptcha(false); 
          handleContactClick();
        }, 500); 
      }, 1000);
    }
  };

  const handleContactClick = () => {
    if (email && !emailClicked) {
      setEmailClicked(true); 
      window.location.href = `mailto:${email}`;
    }
  };

  const handleClick = () => {
    setShowCaptcha(true);
    setHideCaptcha(false); 
  };

  const scrollToSection = (event, id) => {
    event.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="nav-bar" style={{ top: '25px', left: '25px', right: '25px' }}>
      <a href="/"> <img src={logo} alt="Logo" className={`nav-logo ${isScrollingDown ? 'fade-out' : 'fade-in'}`} /></a>

      <div style={{ width: '120px', display: 'flex', justifyContent: 'flex-end' }}>
        <a
          onClick={handleClick}
          className={`nav-link contact-button ${isScrollingDown ? 'fade-out' : 'fade-in'}`}
          style={{ backgroundColor: '#F5F5F5', color: colors.black, cursor: 'pointer' }}
        >
          Contact
        </a>
      </div>

      {showCaptcha && (
        <div className={`captcha-container ${hideCaptcha ? 'hide' : ''}`}>
          <ReCAPTCHA
            sitekey="6Ldzne8pAAAAALJgOOL3PyjC9Ky0hjUYlaDplY49"
            onChange={handleCaptchaChange}
          />
        </div>
      )}
    </div>
  );
}

NavBarPhone.propTypes = {
  hideMiddleSection: PropTypes.bool,
};

export default NavBarPhone;
