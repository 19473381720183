import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import './Button.css';
import './Global.css';
import { fetchEmailData } from './cms/cmsEmail';

function Button({ color, textColor, content }) {
  const [email, setEmail] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [emailClicked, setEmailClicked] = useState(false);
  const [fadeOut, setFadeOut] = useState(false); 

  useEffect(() => {
    const getEmail = async () => {
      const data = await fetchEmailData();
      setEmail(data ? data.email : '');
    };
    getEmail();
  }, []);

  const handleCaptchaChange = (value) => {
    if (value && !emailClicked) {
      setCaptchaVerified(true);
      setTimeout(() => {
        setFadeOut(true); 
        setTimeout(() => {
          handleEmailClick();
        }, 1000); 
      }, 500); 
    }
  };

  const handleEmailClick = () => {
    if (email && !emailClicked) {
      setEmailClicked(true);
      const subject = encodeURIComponent("Free sample request");
      const body = encodeURIComponent("Hi, I'd like to request a free sample of the Nova Carbon® material. My address, contact info and industry are:");
      window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
    }
  };

  const handleClick = () => {
    setShowCaptcha(true);
  };

  return (
    <>
      <button
        className="rounded-button small-text"
        style={{ backgroundColor: color, color: textColor, textAlign: 'center' }}
        onClick={handleClick}
      >
        {content}
      </button>
      {showCaptcha && (
        <div className={`captcha-container ${fadeOut ? 'fade-out' : ''}`}>
          <ReCAPTCHA
            sitekey="6Ldzne8pAAAAALJgOOL3PyjC9Ky0hjUYlaDplY49" // Replace this with your actual site key
            onChange={handleCaptchaChange}
          />
        </div>
      )}
    </>
  );
}

Button.propTypes = {
  color: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default Button;
