// Contact.js
import React, { useState, useEffect } from 'react';
import './Global.css';
import './Contact.css';
import Introducer from './introducer';
import Button from './button';
import ReCAPTCHA from 'react-google-recaptcha';
import { fetchContactData } from './cms/cmsContact';
import { fetchEmailData } from './cms/cmsEmail';
import colors from './colors';
import useIntersectionObserver from './useIntersectionObserver';
import './FadeIn.css'; 

const Contact = ({ phone }) => {
    const [contactData, setContactData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [email, setEmail] = useState('');
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [emailClicked, setEmailClicked] = useState(false);
    const [fadeOut, setFadeOut] = useState(false);
    const [ref, hasIntersected] = useIntersectionObserver({
        root: null,
        rootMargin: '0px',
        threshold: 0.1
    });

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchContactData();
            setContactData(data);
            const emailData = await fetchEmailData();
            setEmail(emailData ? emailData.email : '');
            setIsLoading(false);
        };
        fetchData();
    }, []);

    const handleCaptchaChange = (value) => {
        if (value && !emailClicked) {
            setCaptchaVerified(true);
            setTimeout(() => {
                setFadeOut(true);
                setTimeout(() => {
                    handleEmailClick();
                }, 1000);
            }, 500);
        }
    };

    const handleEmailClick = () => {
        if (email && !emailClicked) {
            setEmailClicked(true);
            const subject = encodeURIComponent("Free sample request");
            const body = encodeURIComponent("Hi, I'd like to request a free sample of the Nova Carbon® material. My address, contact info and industry are:");
            window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
        }
    };

    const handleContainerClick = (index) => {
        if (index === 0) {
            setShowCaptcha(true);
        }
    };

    if (isLoading || !contactData) {
        return <div style={{ height: '200px' }}>Loading...</div>;
    }

    const infoSections = [
        {
            title: contactData.title1,
            subtitle: contactData.body1,
            body: contactData.bottomtext1,
            icon: contactData.icon1.url,
            link: contactData.link1
        },
        {
            title: contactData.title2,
            subtitle: contactData.body2,
            body: contactData.bottomtext2,
            icon: contactData.icon2.url,
            link: contactData.link2
        },
        {
            title: contactData.title3,
            subtitle: contactData.body3,
            body: contactData.bottomtext3,
            icon: contactData.icon3.url,
            link: contactData.link3
        },
        {
            title: contactData.title4,
            subtitle: contactData.body4,
            body: contactData.bottomtext4,
            icon: contactData.icon4.url,
            link: contactData.link4
        }
    ];

    const renderInfoSection = (section, index) => {
        if (index === 0) {
            return (
                <div onMo key={index} className="contact-item" style={{ cursor: 'pointer', height: 'calc(29vh - 12px)', marginBottom: phone ? '15px' : null }} onClick={() => handleContainerClick(index)}>
                    <img src={section.icon} alt={`${section.title} Icon`} className="contact-icon" />
                    <div style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                        <p style={{ paddingBottom: '5px' }} className="large-text">{section.title}</p>
                        <p style={{ color: colors.greyText, paddingRight: '100px', lineHeight: '18px' }} className="small-text">{section.subtitle}</p>
                    </div>
                    <p style={{ color: colors.greyText, paddingRight: '100px' }} className="small-text">{section.body}</p>
                    {showCaptcha && (
                        <div className={`captcha-container ${fadeOut ? 'fade-out' : ''}`}>
                            <ReCAPTCHA
                                sitekey="6Ldzne8pAAAAALJgOOL3PyjC9Ky0hjUYlaDplY49"
                                onChange={handleCaptchaChange}
                            />
                        </div>
                    )}
                </div>
            );
        } else {
            return (
                <a key={index} href={section.link} target='blank' className="contact-item" style={{ textDecoration: 'none', color: colors.black, height: 'calc(29vh - 12px)', marginBottom: phone ? '15px' : null }}>
                    <img src={section.icon} alt={`${section.title} Icon`} className="contact-icon" />
                    <div style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                        <p style={{ paddingBottom: '5px' }} className="large-text">{section.title}</p>
                        <p style={{ color: colors.greyText, paddingRight: '100px', lineHeight: '18px' }} className="small-text">{section.subtitle}</p>
                    </div>
                    <p style={{ color: colors.greyText, paddingRight: '100px' }} className="small-text">{section.body}</p>
                </a>
            );
        }
    };

    if (phone) {
        return (
            <div ref={ref} className={`fade-in-section ${hasIntersected ? 'is-visible' : ''}`} style={{ marginLeft: '15px', marginRight: '15px', paddingBottom: '80px' }}>
                <Introducer
                    phone={phone}
                    sectionTitle={contactData.header}
                    title={contactData.title}
                    body={contactData.body}
                />
                <div className="contact-image-container" style={{ width: '100%', marginBottom: '12px' }}>
                    <img src={contactData.image.url} alt="Contact Image" className="contact-image" style={{ height: 'calc(30vh)' }} />
                    <div className="contact-image-overlay">
                        <p style={{ color: colors.white, paddingBottom: '4px' }} className="large-text">{contactData.titleLeft}</p>
                        <p style={{ color: colors.greyBackground, width: '60%', paddingBottom: '24px' }} className="small-text">{contactData.subtitleLeft}</p>
                        <Button color={colors.green} content={contactData.callToActionLeft} textColor={colors.black} />
                    </div>
                </div>
                {infoSections.map((section, index) => renderInfoSection(section, index))}
            </div>
        );
    } else {
        return (
            <div ref={ref} className={`fade-in-section ${hasIntersected ? 'is-visible' : ''}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <div className="contact-wrapper">
                    <Introducer
                        phone={phone}
                        sectionTitle={contactData.header}
                        title={contactData.title}
                        body={contactData.body}
                    />
                    <div className="contact-container">
                        <div className="contact-left-column">
                            <div className="contact-image-container">
                                <img src={contactData.image.url} alt="Contact Image" className="contact-image" />
                                <div className="contact-image-overlay">
                                    <p style={{ color: colors.white, paddingBottom: '4px' }} className="large-text">{contactData.titleLeft}</p>
                                    <p style={{ color: colors.greyBackground, width: '60%', paddingBottom: '24px' }} className="small-text">{contactData.subtitleLeft}</p>
                                    <Button color={colors.green} content={contactData.callToActionLeft} textColor={colors.black} />
                                </div>
                            </div>
                        </div>
                        <div className="contact-right-column">
                            {infoSections.map((section, index) => renderInfoSection(section, index))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Contact;
